@font-face {
  font-family: "TenorSans";
  src: local("TenorSans"), url("./res/font/TenorSans-Regular.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: 'TenorSans', 'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

* {
  font-family: 'TenorSans', 'sans-serif' !important;
}

code {
  font-family: 'TenorSans', 'TenorSans';
}

html, body, #app, #app>div {
  height: 100%
}
.mantine-UnstyledButton-root {
  color: white;
}
.mantine-UnstyledButton-root:hover,
.mantine-vqlwgr, .mantine-vqlwgr:hover {
  color: black;
}
.mantine-Modal-header {
  font-weight: bold;
}
.container-center {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.flex-column {
  flex-direction: row;
}
.w-100 {
  width: 100%;
}
.web-center{
  text-align: -webkit-center;
}
.mantine-Tooltip-root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.text-error {
  font-size: 12px;
  color: #b2292e;
}
.text-bold-justify{
  text-align: justify;
  font-weight: bold;
}
.text-center{
  text-align: center;
}
.mantine-Notification-description { font-size: 18px !important; }
.mantine-Notification-title { font-size: 20px !important; }
.mantine-1efls8y {
  justify-content: space-around !important;
}